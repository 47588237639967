










import {SignInRecords} from "@/request/marketing/SignInRecords";

export default {
    data() {
        let self: any =this;
        return {
            modal: SignInRecords,
            columns: [
                {
                    title: '昵称',
                    key: 'nickName',
                    minWidth: 120
                },
                {
                    title: '头像',
                    key: 'headImage',
                    minWidth: 80,
                    render: (h: any, params: any) => {
                        return h('img', {
                            style: {
                                borderRadius: '3px',
                                height: '40px',
                                width: '40px',
                                marginTop: '5px'
                            },
                            attrs: {
                                src: params.row.headImage
                            }
                        })
                    }
                },
                {
                    title: '积分',
                    key: 'integral',
                    minWidth: 120
                },
                {
                    title: '时间',
                    key: 'createTime',
                    minWidth: 120
                }
            ]
        }
    }
}
